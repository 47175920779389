export default{
    consoleLog: function(a='', b='', c='', d=''){
        //process.env.VUE_APP_HOST_NAME_LOCK
        if (document.location.origin+'/' != process.env.VUE_APP_HOST_NAME_LOCK){
            console.log(a, b, c, d)
        }
    },
    randomInt: function(min, max){
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    shuffleArray: function(arr) {
        const newArr = arr.slice()
        for (let i = newArr.length - 1; i > 0; i--) {
            const rand = Math.floor(Math.random() * (i + 1));
            [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
        }
        return newArr        
    },
    safeGoal: function(goalName, vueContext){
        this.consoleLog('сабмит', goalName)
        // const counterID = (vueContext.$route.name === 'rotator') ? process.env.VUE_APP_METRIKA_2 : process.env.VUE_APP_METRIKA_1
        let counterID = process.env.VUE_APP_METRIKA_1
        if (vueContext.$route.name === 'rotator'){
            counterID = process.env.VUE_APP_METRIKA_2
        }else if(vueContext.$route.name === 'pwa'){
            counterID = process.env.VUE_APP_METRIKA_3
        }else if(vueContext.$route.name === 'pwa1'){
            counterID = process.env.VUE_APP_METRIKA_4
        }else if(vueContext.$route.name === 'pwa2'){
            counterID = process.env.VUE_APP_METRIKA_5
        }else if(vueContext.$route.name === 'pwa3'){
            counterID = process.env.VUE_APP_METRIKA_6
        }else if(vueContext.$route.name === 'pwa4'){
            counterID = process.env.VUE_APP_METRIKA_7
        }else if(vueContext.$route.name === 'pwa5'){
            counterID = process.env.VUE_APP_METRIKA_8
        }else if(vueContext.$route.name === 'pwa6'){
            counterID = process.env.VUE_APP_METRIKA_9
        }else if(vueContext.$route.name === 'pwa7'){
            counterID = process.env.VUE_APP_METRIKA_10
        }
        const counterName = `yaCounter${counterID}`
        try {
            if (window[counterName]){
                window[counterName].reachGoal(goalName)
            }else{
                let attemptCount = 0
                const trySend = window.setInterval(function(){
                    attemptCount = attemptCount + 1
                    if (window[counterName]){
                        window[counterName].reachGoal(goalName)
                        clearInterval(trySend)
                    }else if (attemptCount === 60){
                        clearInterval(trySend)
                    }                    
                }, 1000)
            }
        } catch(e) {
            console.log(e)
        }
    },
    async timeSend(timeStart){
        if (document.location.origin+'/' === process.env.VUE_APP_HOST_NAME_LOCK){
            const timeNow = parseInt(new Date().getTime())
            const timeDef = timeNow - parseInt(timeStart)
            const url = `${process.env.VUE_APP_HOST_NAME_LOCK}/monitoring/flowready.php?t=${timeDef}`
            await fetch(url)
        }
    },
    itemsCount: function(blockHeight){
        const maxNumOfBlocks = Math.floor(screen.height / blockHeight)
        // const k = 3/5
        const k = 1/3
        const num = Math.floor(maxNumOfBlocks * k) 
        return num
    },
    setFullscreen: function(){
        //document.documentElement.requestFullscreen();

        try{
            //moz
            if (document.documentElement.mozRequestFullScreen){
                this.consoleLog('фуллскрин через moz')
                document.documentElement.mozRequestFullScreen()
            }    
            //webkit        
            else if (document.documentElement.webkitRequestFullscreen){
                this.consoleLog('фуллскрин через webkit')
                document.documentElement.webkitRequestFullscreen()
            }
            //ms        
            else if (document.documentElement.msRequestFullscreen){
                this.consoleLog('фуллскрин через ms')
                document.documentElement.msRequestFullscreen()
            }
            //дефолт
            else{
                this.consoleLog('фуллскрин дефолтным образом')
                document.documentElement.requestFullscreen();
            }            
        } catch(e){
            this.consoleLog('фуллскрин ошибка', e)
        }
        this.consoleLog('request fullscreen was sended')
        return true
    },
    fullScreenAbility: function(){
        return (!!document.documentElement.mozRequestFullScreen || !!document.documentElement.webkitRequestFullscreen || 
            !!document.documentElement.msRequestFullscreen || !!document.documentElement.requestFullscreen) ? true : false
    },
    restAPIpost: function(id){
            //мэтчим сурс
            const sourceMatch = document.location.href.match(/source=([^&]+)/i)
            const source = (sourceMatch && sourceMatch[1]) ? sourceMatch[1] : 'stopnews'
            //проверка на локал ПХП
            const urlBase = (process.env.VUE_APP_LOCAL_PHP === '1') ? 'http://localhost/' : process.env.VUE_APP_HOST_NAME
            //проверка на локал ресурс
            const urlEnd = (process.env.VUE_APP_LOCAL_RESOURCES === '1') ? `content/mock/post/?p=${id}` : `content/post/?p=${id}&source=${source}`
            const url = urlBase+urlEnd
            return url        
    },
    apiIDList: function(){
            //проверка на локал ПХП
            const urlBase = (process.env.VUE_APP_LOCAL_PHP === '1') ? 'http://localhost/' : process.env.VUE_APP_HOST_NAME
            //проверка на локал ресурс
            const urlEnd = (process.env.VUE_APP_LOCAL_RESOURCES === '1') ? `api/mock/id/?t=${Date.now()}` : `api/id/?t=${Date.now()}`
            const url = urlBase+urlEnd
            return url                
    },
    apiModel: function(num){
            //проверка на локал ПХП
            const urlBase = (process.env.VUE_APP_LOCAL_PHP === '1') ? 'http://localhost/' : process.env.VUE_APP_HOST_NAME
            //проверка на локал ресурс
            const urlEnd = `api/model/${num}/?t=${Date.now()}`
            const url = urlBase+urlEnd
            return url                        
    },
    restAPIlist: function(){
        const urlBase = (process.env.VUE_APP_LOCAL_PHP === '0') ? process.env.VUE_APP_HOST_NAME : 'http://localhost/'
        const localParam = (process.env.VUE_APP_LOCAL_RESOURCES === '1') ? 'local=1' : ''
        const url = urlBase+'content/posts/?'+localParam
        return url
    },
    hasReferrer: function(){
        let result = false
        const condition1 = (document.referrer === process.env.VUE_APP_CONTENT_SOURCE_1)
        const condition2 = (document.referrer === process.env.VUE_APP_CONTENT_SOURCE_2)
        //source=stopnews
        const condition3 = (document.location.href.match(/source=stopnews/i))
        const condition4 = (document.location.href.match(/source=newzfeed/i))
        const condition5 = (document.referrer.match(/infox|24smi|adwile/i))
        const condition6 = (document.location.href.match(/infox|infox/i))
        const condition7 = (document.location.href.match(/24smi|adwile/i))
        const condition8 =(document.location.href.match(/smi2/i) || document.referrer.match(/smi2/i))
        const condition9 = (document.cookie.match(/from_external=1/i))
        if (condition1 || condition2 || condition3 || condition4 || condition5 || condition6 || condition7 || condition8 || condition9){
            result = true
            document.cookie = 'from_external=1'
        }
        return result
    },
    hasReffererSmi24: function(){
        return (document.location.href.match(/24smi/i) || document.referrer.match(/24smi/i))
    },
    hasReffererSmi2: function(){
        return (document.location.href.match(/smi2/i) || document.referrer.match(/smi2/i))
    },  
    hasReferrerInfox: function(){
        return (document.location.href.match(/infox/i) || document.referrer.match(/infox/i))
    },
    getIdFromAddressBar: function(){
        const matchP = document.location.href.match(/\?p=([\d]+)/i)
        return (matchP && matchP[1]) ? matchP[1] : false
    },
    isProd: function(){
        return (process.env.VUE_APP_HOST_NAME === document.location.origin+'/')
    },
    isRotator: function(vueContext){
        const routeName = vueContext.$route.name
        return ( routeName === 'app' || routeName === 'pwa' || routeName === 'pwa1' 
            || routeName === 'pwa2' || routeName === 'pwa3' || routeName === 'pwa4'
            || routeName === 'pwa5' || routeName === 'pwa6' || routeName === 'pwa7')
    },
    isPost: function(vueContext){
        return (vueContext.$route.name === 'post')
    },
    isHome: function(vueContext){
        return (vueContext.$route.name === 'home')
    },        
    localResources: function(){
        return (process.env.VUE_APP_LOCAL_RESOURCES === '1')
    },
    async getPixel(zeroPixel){
        await fetch(zeroPixel)
    },
    closureIncrement: function () {
        let count = 0
        return function () {
            return count++
        }
    }, 
    uuidv4: function () {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
          );
    }, 
}